import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-lowell-massachusetts.png'
import image0 from "../../images/cities/scale-model-of-big-egg-in-lowell-massachusetts.png"
import image1 from "../../images/cities/scale-model-of-jack-kerouac's-grave-in-lowell-massachusetts.png"
import image2 from "../../images/cities/scale-model-of-whistler-house-museum-of-art-in-lowell-massachusetts.png"
import image3 from "../../images/cities/scale-model-of-stony-brook-conservation-land-in-lowell-massachusetts.png"
import image4 from "../../images/cities/scale-model-of-greater-merrimack-valley-in-lowell-massachusetts.png"
import image5 from "../../images/cities/scale-model-of-lowell-national-historic-park-in-lowell-massachusetts.png"
import image6 from "../../images/cities/scale-model-of-the-butterfly-place-in-lowell-massachusetts.png"
import image7 from "../../images/cities/scale-model-of-bruce-freeman-rail-trail-in-lowell-massachusetts.png"
import image8 from "../../images/cities/scale-model-of-varney-playground-in-lowell-massachusetts.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Lowell'
            state='Massachusetts'
            image={image}
            lat='42.639'
            lon='-71.3211'
            attractions={ [{"name": "Big Egg", "vicinity": "140-30 Orchard St, Tewksbury", "types": ["point_of_interest", "establishment"], "lat": 42.60541569999999, "lng": -71.2208167}, {"name": "Jack Kerouac's Grave", "vicinity": "5 Carlisle St, Lowell", "types": ["point_of_interest", "establishment"], "lat": 42.6160091, "lng": -71.3067878}, {"name": "Whistler House Museum Of Art", "vicinity": "243 Worthen St, Lowell", "types": ["art_gallery", "museum", "point_of_interest", "establishment"], "lat": 42.644589, "lng": -71.31523179999999}, {"name": "Stony Brook Conservation Land", "vicinity": "Westford", "types": ["park", "point_of_interest", "establishment"], "lat": 42.60030099999999, "lng": -71.44477849999998}, {"name": "Greater Merrimack Valley", "vicinity": "61 Market St #1c, Lowell", "types": ["point_of_interest", "establishment"], "lat": 42.64764, "lng": -71.30891589999999}, {"name": "Lowell National Historic Park", "vicinity": "67 Kirk St, Lowell", "types": ["park", "point_of_interest", "establishment"], "lat": 42.6472093, "lng": -71.3095624}, {"name": "The Butterfly Place", "vicinity": "120 Tyngsboro Rd, Westford", "types": ["park", "point_of_interest", "establishment"], "lat": 42.642032, "lng": -71.43966290000003}, {"name": "Bruce Freeman Rail Trail", "vicinity": "Bruce Freeman Rail Trail, Chelmsford", "types": ["point_of_interest", "establishment"], "lat": 42.5569877, "lng": -71.39114489999997}, {"name": "Varney Playground", "vicinity": "45 Varney Ave, North Chelmsford", "types": ["park", "point_of_interest", "establishment"], "lat": 42.6373934, "lng": -71.3872983}] }
            attractionImages={ {"Big Egg":image0,"Jack Kerouac's Grave":image1,"Whistler House Museum Of Art":image2,"Stony Brook Conservation Land":image3,"Greater Merrimack Valley":image4,"Lowell National Historic Park":image5,"The Butterfly Place":image6,"Bruce Freeman Rail Trail":image7,"Varney Playground":image8,} }
       />);
  }
}